import React from 'react';
import Buttons from './Buttons';

const HeaderArticlePages = () => (
  <header>
    <Buttons />
  </header>
);

export default HeaderArticlePages;
